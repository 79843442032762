@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&family=Stick+No+Bills&family=Mulish&display=swap');


.rest {
    font-family: 'Mulish', sans-serif;
  }
  
.twenty_title {
    font-family: 'Rock Salt', sans-serif;
}

.frames_title {
    font-family: 'Stick No Bills', sans-serif;
}


.ro {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14%2c20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}